import React from "react";
import "./ContactForm.css";
import { Formik } from "formik";

export const ContactForm = () => {
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await fetch("/api/index.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(values)
      });
      if (response.status === 200) {
        alert("Your message has been sent!");
        resetForm();
      }
    } catch (error) {
      console.error("Error:", error);
      alert(
        "An error occurred while sending the message. Please try again later."
      );
    } finally {
      setSubmitting(false);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = "First name is required.";
    } else if (!/^[A-Za-z]{3,16}$/i.test(values.first_name)) {
      errors.first_name =
        "First name must be between 3 and 16 characters long.";
    }

    if (!values.last_name) {
      errors.last_name = "Last name is required.";
    } else if (!/^[A-Za-z]{3,16}$/i.test(values.last_name)) {
      errors.last_name = "Last name must be between 3 and 16 characters long.";
    }

    if (!values.email) {
      errors.email = "Email is required.";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address.";
    }

    if (!values.message) {
      errors.message = "Message is required.";
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{ first_name: "", last_name: "", email: "", message: "" }}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
      }) => (
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="first_name"
            placeholder="First Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.first_name}
          />
          {errors.first_name && touched.first_name && (
            <div className="error">{errors.first_name}</div>
          )}

          <input
            type="text"
            name="last_name"
            placeholder="Last Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.last_name}
          />
          {errors.last_name && touched.last_name && (
            <div className="error">{errors.last_name}</div>
          )}

          <input
            type="email"
            name="email"
            placeholder="E-Mail"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          {errors.email && touched.email && (
            <div className="error">{errors.email}</div>
          )}

          <textarea
            type="message"
            name="message"
            placeholder="Message"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.message}
          />
          {errors.message && touched.message && (
            <div className="error">{errors.message}</div>
          )}

          <button type="submit" disabled={isSubmitting || !isValid}>
            Submit
          </button>
        </form>
      )}
    </Formik>
  );
};