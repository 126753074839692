export const SKILLS = [
    {
        title: "Frontend",
        icon: "./assets/images/front-end.png",
        skills: [
            { skill: "HTML5", percentage: "75%" },
            { skill: "CSS3", percentage: "70%" },
            { skill: "JavaScript", percentage: "70%" },
            { skill: "React.js", percentage: "70%" },
        ],
    },
    {
        title: "Backend",
        icon: "./assets/images/backend.png",
        skills: [
            { skill: "Java", percentage: "70%" },
            { skill: "Node.js", percentage: "65%" },
        ],
    },
    {
        title: "Tools",
        icon: "./assets/images/tools.png",
        skills: [
            { skill: "Git & GitHub", percentage: "70%" },
            { skill: "IntelliJ IDE", percentage: "80%" },
            { skill: "Visual Studio Code", percentage: "75%" },
        ],
    },
    {
        title: "Soft Skills",
        icon: "./assets/images/softskill.png",
        skills: [
            { skill: "Attention to Detail", percentage: "85%" },
            { skill: "Collaboration", percentage: "90%" },
            { skill: "Problem Solving", percentage: "85%" },
        ],
    },
];

export const WORK_EXPERIENCE = [
    {
        title: "Career Transition",
        date: "August 2022 - April 2024",
        responsibilities: [
            "Change focus to different career goal and remote working, therefore I took a break from my career and had training on web development.",
        ],
    },
    {
        title: "Key Account Manager at Bellwoven Turkey",
        date: "May 2018 - May 2022",
        responsibilities: [
            "Follow up, manage, and finalize the order requests from customers at every stage. Be responsible for arranging customers to receive orders at the time of completion, following up on the procedure, reporting the process and collecting feedback. Import raw materials from suppliers and export finished products to customers. Develop new products and make price inquiries regarding the products. Be responsible for current customer care and manage crises related to the brands.",
        ],
    },
    {
        title: "Operation Executive at UPI Trans Dış Ticaret A.Ş.",
        date: "December 2017 - May 2018",
        responsibilities: [
            "Follow up with customers, orders and trucks using Soft Trans software. Prepare customs clearance and documents for related shipments. Communicate with the agency in Germany and arrange transshipment plans. Prepare freight offers and handle requests for loads to be carried out.",
        ],
    },
    {
        title: "Import Executive Manager at Erbaşlar Kağıtçılık",
        date: "July 2017 - November 2017",
        responsibilities: [
            "Manage the operational process, prepare import documents, follow up on declaration closures, execute relations with customs and monitor stock levels.",
        ],
    },
];