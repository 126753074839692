import React from 'react'
import './ContactInfoCard.css'
import { Link } from 'react-router-dom';

const ContactInfoCard = ({ iconUrl, text, URL }) => {
    return (
        <div className='contact-details-card'>
            <div className='icon'>
                <img src={iconUrl} alt={Link} />
            </div>
            <a href={URL}>{text}</a>
        </div>
    );
};

export default ContactInfoCard