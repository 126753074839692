import React, { useState } from "react";
import "./Navbar.css";
import MobileNav from "./MobileNav/MobileNav";
import "material-symbols";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const scrollToS = () => {
    window.scrollTo(0, 740);
  };

  const scrollToH = () => {
    window.scrollTo(0, 0);
  };

  const scrollToW = () => {
    window.scrollTo(0, 1275);
  };

  const scrollToC = () => {
    window.scrollTo(0, 1650);
  };


  return (
    <>
      <MobileNav isOpen={openMenu} toggleMenu={toggleMenu} />

      <nav className="nav-wrapper">
        <div className="nav-content">
        <img className="logo" src="./assets/images/a-c-e-logo.png" alt="" />

          <ul>
            <li>
              <a className="menu-item" onClick={scrollToH}>
                Home
              </a>
            </li>
            <li>
              <a className="menu-item" onClick={scrollToS}>
                Skills
              </a>
            </li>
            <li>
              <a className="menu-item" onClick={scrollToW}>
                Work Experience
              </a>
            </li>
            <li>
              <a className="menu-item" onClick={scrollToC}>
                Contact Me
              </a>
            </li>
            <a className="menu-item" href="./assets/images/Arda-Ciba-Eren-CV.pdf">Download my CV</a>
          </ul>

          <button className="menu-btn" onClick={toggleMenu}>
            <span
              className={"material-symbols-outlined"}
              style={{ fontSize: "1.8rem" }}
            >
              {openMenu ? "close" : "menu"}
            </span>
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar;