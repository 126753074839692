import React from "react";
import "./MobileNav.css";

const MobileNav = ({ isOpen, toggleMenu }) => {
  const scrollToS = () => {
    window.scrollTo(0, 650);
  };

  const scrollToH = () => {
    window.scrollTo(0, 0);
  };

  const scrollToW = () => {
    window.scrollTo(0, 1375);
  };

  const scrollToC = () => {
    window.scrollTo(0, 1950);
  };

  return (
    <>
      <div
        className={`mobile-menu ${isOpen ? "active" : ""}`}
        onClick={toggleMenu}
      >
        <div className="mobile-menu-container">
          <img className="logo" src="./assets/images/a-c-e-logo.png" alt="" />

          <ul>
            <li>
              <a className="menu-item" onClick={scrollToH}>
                Home
              </a>
            </li>
            <li>
              <a className="menu-item" onClick={scrollToS}>
                Skills
              </a>
            </li>
            <li>
              <a className="menu-item" onClick={scrollToW}>
                Work Experience
              </a>
            </li>
            <li>
              <a className="menu-item" onClick={scrollToC}>
                Contact Me
              </a>
            </li>
            <a className="menu-item" href="./assets/images/Arda-Ciba-Eren-CV.pdf">Download my CV</a>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MobileNav;
