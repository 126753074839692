import React, { useEffect } from "react";
import "./Hero.css";

const Hero = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//rf.revolvermaps.com/0/0/8.js?i=5d2yj8jkav3&m=2&c=fff600&cr1=0006ff&f=tahoma&l=33";
    script.async = true;

    const mapContainer = document.getElementById('world-map-container');
    if (mapContainer) {
      mapContainer.appendChild(script);
    }

    return () => {
      if (mapContainer && script) {
        mapContainer.removeChild(script);
      }
    };
  }, []);

  return (
    <section className="hero-container">
      <div className="hero-content">
        <h2>Nice to have you here!</h2>
        <p>
          Though new to the field, I'm dedicated to mastering backend technologies and contributing impactful solutions.
        </p>
        <div id="world-map-container" style={{ width: '100%', height: '400px'}}></div>
      </div>

      <div className="hero-img">
        <div>
          <img src="./assets/images/img.png" alt="" />
        </div>

        <div>
          <div className="tech-icon">
            <img src="./assets/images/react.png" alt="" />
          </div>
          <div className="tech-icon">
            <img src="./assets/images/java.png" alt="" />
          </div>
          <div className="tech-icon">
            <img src="./assets/images/html.png" alt="" />
          </div>
          <div className="tech-icon">
            <img src="./assets/images/css.png" alt="" />
          </div>
          <div className="tech-icon">
            <img src="./assets/images/js.png" alt="" />
          </div>
        </div>
      </div>

      
    </section>
  );
};

export default Hero;